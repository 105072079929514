import { _apiCall } from "../../../backend/axios"
import { buildQueryString, returnAnswerData, returnApiAnswer } from "../../../backend/helpers"

export function getKidsSchoolFromCenter(data, theme) {
    return _apiCall(
        "read/kids/centers/" + data.centerId + "/schools/types?includeTypesForArea=true",
        "GET",
        { activity: theme },
    ).then((response)=> {
        return response.data.data
    })
}

export function getKidsSchoolFromCenters(centerIds, ageCategory) {
    const centerIdsParams = centerIds.map((id) => `centersId[]=${id}`).join("&")

    return _apiCall(
        `read/kids/school/types?${centerIdsParams}&ageCategory=${ageCategory}`,
        "GET",
    ).then(returnAnswerData)
}

export function getAllKidsSchoolsSubscriptions(params) {
    const query = buildQueryString(params)
    return _apiCall(
        "read/kids/school/subscriptions/all" + query,
        "GET",
    ).then((response) => {
        return {
            count: response.data.data.hitCount ?? response.data.data.length ?? 0,
            items: response.data.data.items ?? response.data.data,
        }
    })
}

/**
   * @param id subscription id to apply the discount
   * @param code the discount code
   */
export function addSchoolDiscountCode(id, code) {
    return _apiCall(
        "modify/kids/school/subscription/" + id + "/discountCode/add",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        { code: code.toUpperCase() },
    ).then((response) => {
        return response
    })
}

export function deleteSchoolDiscountCode(id, code) {
    return _apiCall(
        "modify/kids/school/subscription/" + id + "/discountCode/delete",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        { code: code.toUpperCase() },
    ).then((response) => {
        return response
    })
}

// SCHOOL
export function deleteSchoolProduct(id, productId, size) {
    return _apiCall(
        "modify/kids/school/" + id + "/product/delete/" + productId + "/" + size,
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
    ).then((response) => {
        return response.data.data
    })
}
  
/**
   * Retourne une liste d'écoles
   */
export function getKidsSchools(params) {
    let uri = "read/kids/schools/list"
    if (Object.keys(params).length > 0) {
        uri += "?"
        Object.keys(params).forEach((key, idx) => {
            uri += (key + "=" + params[key] +
        (idx === Object.keys(params).length - 1 ? "" : "&"))
        })
    }
    
    return _apiCall(
        uri,
        "GET",
    ).then(
        (response) => {
            if (response) {
                return response.data.data ? response.data.data : []
            } else {
                return response
            }
        },
    )
}

/**
  * Retourne les informations concernant une école donnée par son identifiant
  */
export function getKidsSchoolDetail(id) {
    return _apiCall(
        "read/kids/school/" + id,
        "GET",
    ).then((response) => {
        if (response) {
            return response.data
        }
    })
}

/**
   * @param subscriptionId
   * @param dailySlot
   * @param firstSubscriptionId
   * @param child
   * @param options
   * @param waitingForAvailability
   * @param informations
   */
export function editSchoolSecondSubscription(data) {
    return _apiCall(
        "modify/kids/school/bis/edit",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        data,
    ).then((response) => {
        return response.data
    })
}

/**
   * @param child id of the child
   * @param childFirstname
   * @param childLastname
   * @param childBirthDate format : d/m/Y
   * @param childGender
   * @param options
   * @param correlationId id of the second subscription
   * @param waitingForAvailability
   * @param contact1Firstname
   * @param contact1Lastname
   * @param contact1Phone
   * @param contact1Email
   * @param contact2Firstname
   * @param contact2Lastname
   * @param contact2Phone
   * @param contact2Email
   * @param subscriptionId
   * @param dailySlot
   * @param informations
  */
export function editSchoolSubscription(data) {
    return _apiCall(
        "modify/kids/school/edit",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        data,
    ).then((response) => {
        return response.data
    })
}

/**
   * @param subscriptionId
   * @param secondSessionId
   */
export function preregisterSchoolSubscription(data) {
    return _apiCall(
        "modify/kids/school/subscription/preregister",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        data,
    ).then((response) => {
        return response.data.data
    })
}

// addStageProduct
export function addSchoolProduct(id, productId, size, quantity) {
    return _apiCall(
        "modify/kids/school/" + id + "/product/add",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        }, {
            ["product-" + productId]: size,
            ["quantity-product-" + productId]: quantity,
        },
    ).then((response) => {
        return response
    })
}

export function addSchoolOption(id, optionId) {
    return _apiCall(
        "modify/kids/school/" + id + "/option/add",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        { option: optionId },
    ).then((response) => {
        if (response !== undefined) {
            if (response.data !== undefined) {
                return response.data.data
            }
        }
        return response
    })
}

export function deleteSchoolOption(id, optionId) {
    return _apiCall(
        "modify/kids/school/" + id + "/option/delete",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        { option: optionId },
    ).then((response) => {
        return response.data.data
    })
}

/**
   * @param withMultiPayments
   * @param withAlias
   * @param subscriptionId
   * @param secondSessionId
   * @param returnUrl
   */
export function generatePayzenSchoolPaymentReqs(data, activity) {
    return _apiCall(
        "create/profil/payment/card/kids/school/payment/reqs",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
            activity,
        },
        data,
    ).then((response) => {
        if (response?.data?.data) {
            return response.data.data
        }
        return []
    })
}

export function getKidsSchoolSubscription(id) {
    return _apiCall(
        "read/kids/school/subscription/" + id,
        "GET",
    ).then(
        (response) => {
            return response?.data?.data
        },
    )
}

export function removeSchoolMedicalCertificate(subid, fileid) {
    return _apiCall(
        "modify/kids/school/" + subid + "/attachments/delete/" + fileid,
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
    ).then(
        (response) => {
            return response.data.data
        },
    )
}

export function addSchoolMedicalCertificate(id, file, progressBarEl) {
    
    return _apiCall(
        "modify/kids/school/" + id + "/add/certificate",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        { attachment: file },
        null,
        null,
        null,
        true,
        progressBarEl,
    ).then((response) => {
        if (response.data.data) {
            if (response.data.data.subscription) {
                return response.data.data.subscription
            } else if (response.data.data.subscriptions) {
                return response.data.data.subscriptions
            }
        } else {
            return null
        }
    })
}

export function cancelSchoolRegistration(id) {
    return _apiCall(
        "modify/kids/school/" + id + "/cancel",
        "POST",
        {
            "Content-Type" : "multipart/form-data",
        },
        {},
    ).then((response) => {
        if (response?.data?.data) {
            return response.data.data.subscriptions
        } else {
            return null
        }
    })
}

export function getPrivateSchool(privateCode) {
    return _apiCall(
        `read/kids/school/private/${privateCode}`,
        "GET",
    ).then(returnApiAnswer)
}
