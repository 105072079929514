import { useDidomiDataLayerContext } from "../../contexts/DidomiDataLayerContext"

import "./DidomiDataLayerButton.scss"

export const DidomiDataLayerButton = () => {
    const { toggleModal, events,isModalOpen } = useDidomiDataLayerContext()

    return (
        <button 
            className="didomi-datalayer-button" 
            onClick={toggleModal}
            title="View DataLayer Events"
        >
            {isModalOpen ? (
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#e8eaed">
                    <path
                        d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#e8eaed">
                    <path
                        d="M480-118 120-398l66-50 294 228 294-228 66 50-360 280Zm0-202L120-600l360-280 360 280-360 280Zm0-280Zm0 178 230-178-230-178-230 178 230 178Z"/>
                </svg>
            )}

            {events.length > 0 && (
                <span className="badge">{events.length}</span>
            )}
        </button>
    )
}

export default DidomiDataLayerButton 
