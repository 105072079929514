import {
    useSchoolTrainingCurrent,
    useSchoolTrainingTunnel,
} from "../../../../components/SchoolTrainingSubscriptionContext/SchoolTrainingSubscriptionContext"
import PrestationSuppSchool from "../../../kids/ecoleDeFoot/steps/PrestationSupp"
import PrestationSuppTraining from "../../../teams/urbanCup/steps/PrestationSupp"

import "./SchoolTrainingPresta.scss"

export default function SchoolTrainingPresta() {
    const { value: tunnelType } = useSchoolTrainingTunnel()
    const { value: { selectedSlot } } = useSchoolTrainingCurrent()

    const haveOption = () => {
        const target = slot => tunnelType === "TRAINING" ? slot : slot?.schoolDetails
        return target(selectedSlot)?.optionProducts?.filter(option => !option.showAtPayment)?.length > 0
    }
    const stepNumber = haveOption() ? 4 + "/" : undefined
    return (
        <>
            {tunnelType === "SCHOOL" && <PrestationSuppSchool numero={stepNumber} tunnelType={tunnelType} updateSearchParams />}
            {tunnelType === "TRAINING" && <PrestationSuppTraining numero={stepNumber} tunnelType={tunnelType} updateSearchParams />}
        </>
    )
}
