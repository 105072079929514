import { func, string } from "prop-types"

import {
    useSchoolTrainingCurrent,
    useSchoolTrainingTunnel,
} from "../../../../components/SchoolTrainingSubscriptionContext/SchoolTrainingSubscriptionContext"
import OrderSummarySchool from "../../../kids/steps/OrderSummary"
import OrderSummaryTraining from "../../../payments/OrderSummary/OrderSummary"

import "./SchoolTrainingOrderSummary.scss"

export default function SchoolTrainingOrderSummary() {
    const { value: tunnelType } = useSchoolTrainingTunnel()
    const { value: { isAdult, selectedSlot, skipPrestaSup } } = useSchoolTrainingCurrent()
    
    const target = slot => tunnelType === "TRAINING" ? slot : slot?.schoolDetails

    let stepNumber = 7
        - (isAdult ? 1 : 0)
        - (skipPrestaSup ? 1 : 0)
        - (!target(selectedSlot)?.catalogProducts?.length ? 1 : 0)
    
    const params = {
        backToStep: stepNumber - (isAdult ? 2 : 1),
        numero: stepNumber + "/",
        tunnelType,
    }

    return (
        <>
            {tunnelType === "SCHOOL" && <OrderSummarySchool {...params} customCls="orderSummary" />}
            {tunnelType === "TRAINING" && <OrderSummaryTraining {...params} />}
        </>
    )
}

SchoolTrainingOrderSummary.propTypes = {
    theme: string,
    updateSearchParams: func,
}
