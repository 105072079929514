import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { bool, func, oneOfType } from "prop-types"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"

import ButtonCta from "../../../../components/Buttons/button/button-cta.jsx"
import CurrencyDisplay from "../../../../components/currencies/CurrencyDisplay"
import CheckBlock from "../../../../components/Forms/CheckBlock/CheckBlock"
import FormValidator from "../../../../components/Forms/FormValidator"
import { TextInput } from "../../../../components/Inputs/TextInput/TextInput.jsx"
import Preloader from "../../../../components/loaders/preloader/preloader"
import PhoneCallHelp from "../../../../components/popin/help/phoneCall"
import MessagePopin from "../../../../components/popin/MessagePopin/MessagePopin.jsx"
import ReservationSlotInfoCard from "../../../../components/reservation/slotInfoCard/ReservationSlotInfoCard"
import Step from "../../../../components/stepsManagement/Step"
import HourDisplay from "../../../../components/Time/HourDisplay"
import slugify from "../../../../entries/main/contents/main/helpers/removeAccent.js"
import useQueryParametersObject from "../../../../hooks/routing/useQueryParametersObject.js"
import useIsMobile from "../../../../hooks/useIsMobile.js"
import days from "../../../../model/enums/dayValues"
import genderValues from "../../../../model/enums/genderValues"
import ModalHandler from "../../../../providers/Modal/ModalHandler.jsx"
import TimeSort from "../../../../services/Sorting/TimeSort"
import { checkSkipStepEDF } from "../../../../store/actions/scaffold"
import { clearSchoolOptions, setSchoolSubscription } from "../../../../store/cart/actions"
import { clearAllForms, removeFormEntry, setFormEntry } from "../../../../store/forms/actions"
import { goToStep } from "../../../../store/stepsManagement/actions"
import PreForm from "../../components/preForm/PreForm.jsx"
import ChooseCenterAndType from "../../steps/ChooseCenterAndType"
import MesEnfants from "../../steps/MesEnfants"
import { editSchoolSubscription, getAllKidsSchoolsSubscriptions, getKidsSchools } from "../api"

dayjs.extend(customParseFormat)

const SchoolSlots = ({
    navigate,
    ...props
}) => {
    // State declarations
    const [ actualSubscriptions, setActualSubscriptions ] = useState(undefined)
    const [ ajaxLoading, setAjaxLoading ] = useState(false)
    const [ canLoadTimeslots, setCanLoadTimeslots ] = useState(false)
    const isMobile = useIsMobile()
    const [ lastChooseCenterAndType, setLastChooseCenterAndType ] = useState(undefined)
    const [ lastMesEnfants, setLastMesEnfants ] = useState(undefined)
    const [ checkSkipStepEDFState, setCheckSkipStepEDFState ] = useState(undefined)
    const [ selectedTimeslotDetails, setSelectedTimeslotDetails ] = useState(undefined)
    const [ privateCode, setPrivateCode ] = useState("")
    const [ currentPrivateCode, setCurrentPrivateCode ] = useState("")
    const [ errorMsg, setErrorMsg ] = useState(null)
    // Redux hooks
    const dispatch = useDispatch()
    const forms = useSelector(state => state.forms)
    const stepManagement = useSelector(state => state.stepManagement)
    const userLoaded = useSelector(state => state.userLoaded)
    // Refs
    const stepRef = useRef()
    const formRef = useRef()

    const [ , setSearchParams ] = useSearchParams()
    const searchParamsObject = useQueryParametersObject()

    // Constants
    const formName = "choixCreneau"
    const formDataName = "creneauxData"

    // Close all tooltip descriptions
    const closeAllTooltipDescription = () => {
        const tooltips = document.querySelectorAll(".tooltip-description")
        tooltips.forEach(t => t.classList.remove("active"))
    }

    // Component initialization
    useEffect(() => {
        document.querySelector("#content")?.scrollTo(0, 0)
        document.querySelector("html")?.scrollTo(0, 0)
        window.scrollTo(0, 0)

        const schoolSub = forms[formName]?.selectedTimeslot || {}
        const firstSubscription = forms.ozmoSubscription?.firstSubscription || {}

        let schoolDetail = schoolSub?.schoolDetails || firstSubscription.school

        if (schoolDetail) {
            if (schoolDetail.full) {
                stepRef.current?.unvalidate()
            }
        }

        const mesEnfants = forms[MesEnfants.formName]
        if (mesEnfants?.selectedKid) {
            setLastMesEnfants(mesEnfants)
        }

        if (forms[formName]?.selectedTimeslot) {
            const skipStepAction = dispatch(checkSkipStepEDF(forms, forms[formName].selectedTimeslot))
            setCheckSkipStepEDFState(skipStepAction.payload)
        }
    }, [])

    // Validation functions
    const validateStep = () => {
        if (selectedTimeslotDetails) {
            if (!selectedTimeslotDetails.full && selectedTimeslotDetails.canRegister) {
                stepRef.current?.validate()
            } else {
                stepRef.current?.unvalidate()
            }
        }
    }

    const unvalidateStep = () => {
        stepRef.current?.unvalidate()
    }

    // Render description card
    const renderDescriptionCard = (timeslots, selectedTimeslotDetails) => {
        const form = forms[formName] || { selectedTimeslot: {} }
        const selectedTimeslot = form.selectedTimeslot || {}

        if (selectedTimeslotDetails?.id !== selectedTimeslot.value) {
            return null
        }

        return timeslots && Object.values(timeslots).length > 0 ? (
            <ReservationSlotInfoCard
                reservationSlot={selectedTimeslotDetails}
                tunnelType="SCHOOL"
                subscribeToWaitingListCallback={subscribeToWaitingList}
                slotName="créneau"
                pleaseSelectText="le jour et le créneau de votre séance."
            />
        ) : <div />
    }

    // Get available slots
    const getAvailableSlots = (privateAction) => {
        const choixEcole = forms[ChooseCenterAndType.formName]
        const mesEnfants = forms[MesEnfants.formName]
        const formCreneau = forms[formName]
        const formDataCreneau = forms[formDataName] || {}

        let selectedKidId
        let selectedKidBirthYear
        if (mesEnfants?.selectedKid && !privateAction) {
            selectedKidId = mesEnfants.selectedKid.id
            selectedKidBirthYear = mesEnfants.kidBirthYear
        }

        // Update store if school type, center or child has changed
        if (formDataCreneau && selectedKidId && choixEcole && !privateAction) {
            if (
                formDataCreneau.lastTypeId !== choixEcole.typeId ||
                formDataCreneau.lastCenterId !== choixEcole.centerId ||
                formDataCreneau.lastSelectedKidId !== selectedKidId ||
                formDataCreneau.lastSelectedKidBirthYear !== selectedKidBirthYear
            ) {
                let actualKidSubscriptionsIds = []
                if (actualSubscriptions) {
                    actualSubscriptions.forEach(subscription => {
                        if (subscription.kid?.id === selectedKidId) {
                            actualKidSubscriptionsIds.push(subscription.school.id)
                        }
                    })
                }

                dispatch(setFormEntry(formDataName, {
                    ...formDataCreneau,
                    actualKidSubscriptionsIds,
                    lastCenterId: choixEcole.centerId,
                    lastSelectedKidBirthYear: selectedKidBirthYear,
                    lastSelectedKidId: selectedKidId,
                    lastTypeId: choixEcole.typeId,
                }))
            }
        }

        const getCreneaux = (actualKidSubscriptionsIds) => {
            if (
                choixEcole &&
                mesEnfants?.kidBirthYear &&
                lastChooseCenterAndType &&
                (
                    lastChooseCenterAndType.centerId !== choixEcole.centerId ||
                    lastChooseCenterAndType.typeId !== choixEcole.typeId ||
                    !lastMesEnfants ||
                    lastMesEnfants.kidBirthYear !== mesEnfants.kidBirthYear
                )
                || privateAction
            ) {
                dispatch(setFormEntry(formDataName, {
                    ...formDataCreneau,
                    timeslots: undefined,
                }))

                if (mesEnfants?.kidBirthYear?.includes("-")) {
                    mesEnfants.kidBirthYear = mesEnfants.kidBirthYear.split("-")[0]
                } else if (mesEnfants?.kidBirthYear?.includes("/")) {
                    mesEnfants.kidBirthYear = mesEnfants.kidBirthYear.split("/")[2]
                }
                
                const data = {
                    birthYear: mesEnfants?.kidBirthYear?.split("-")[0],
                    "centersId[0]": choixEcole.centerId,
                    schoolType: choixEcole.typeId,
                }

                if ((currentPrivateCode && privateAction) || forms[formName]?.privateCode) {
                    data.privateCode = currentPrivateCode || forms[formName]?.privateCode
                }

                setCanLoadTimeslots(false)
                setAjaxLoading(true)

                getKidsSchools(data).then((res) => {
                    if (res) {
                        const timeslots = {}
                        res.sessions.forEach(timeslot => {
                            timeslots[timeslot.value] = timeslot
                        })

                        // If using private code and exactly one slot is found, automatically select it
                        if (currentPrivateCode && privateAction && res.sessions.length === 1) {
                            const selectedTimeslot = res.sessions[0]
                            dispatch(setFormEntry(formName, {
                                ...forms[formName],
                                privateCode: currentPrivateCode || forms[formName]?.privateCode,
                                selectedTimeslot: selectedTimeslot,
                                selectedTimeslotValue: selectedTimeslot.value,
                            }))

                            if (selectedTimeslot.schoolDetails) {
                                setSelectedTimeslotDetails(selectedTimeslot.schoolDetails)
                                
                                const skipStepAction = dispatch(checkSkipStepEDF(forms, selectedTimeslot, true))
                                setCheckSkipStepEDFState(skipStepAction.payload)

                                if (selectedTimeslot.schoolDetails.full || !selectedTimeslot.schoolDetails.canRegister) {
                                    stepRef.current?.unvalidate()
                                } else {
                                    stepRef.current?.validate()
                                }

                                dispatch(setSchoolSubscription(selectedTimeslot.schoolDetails.id, selectedTimeslot.schoolDetails.amount))
                            }
                        } else {
                            dispatch(setFormEntry(formName, {
                                ...forms[formName],
                                privateCode: null,
                            }))
                        }

                        dispatch(setFormEntry(formDataName, {
                            ...formDataCreneau,
                            ...(actualKidSubscriptionsIds ? { actualKidSubscriptionsIds } : {}),
                            timeslots,
                        }))
                    }
                    setAjaxLoading(false)
                }).catch(() => {
                    // retry without private code and unset it
                    if (currentPrivateCode) {
                        setCurrentPrivateCode(null)
                        setErrorMsg("Code invalide")
                        getAvailableSlots()
                    }
                })
            }
        }

        const lastId = lastMesEnfants?.selectedKid?.id
        const newId = mesEnfants?.selectedKid?.id

        // Get current subscriptions if child is selected for first time or changes
        if (
            (!lastMesEnfants && mesEnfants) ||
            (lastId !== newId) ||
            (choixEcole && lastChooseCenterAndType && lastChooseCenterAndType.centerId !== choixEcole.centerId)
        ) {
            dispatch(setFormEntry(formName, {
                ...formCreneau,
                selectedTimeslot: undefined,
                selectedTimeslotValue: undefined,
            }))

            setAjaxLoading(true)
            setCanLoadTimeslots(true)

            const periodStart = dayjs().subtract(3, "year").format("YYYY-MM-DD")

            getAllKidsSchoolsSubscriptions({
                offset: 0,
                orderByAsc: false,
                periodStart,
                status: "[2,1,3]",
            }).then((data) => {
                const subscriptions = data.items
                let actualKidSubscriptionsIds = []

                if (actualSubscriptions) {
                    actualSubscriptions.forEach(subscription => {
                        if (subscription.kid?.id === selectedKidId) {
                            actualKidSubscriptionsIds.push(subscription.school.id)
                        }
                    })
                }

                setActualSubscriptions(subscriptions)
                setAjaxLoading(false)

                if (canLoadTimeslots) {
                    getCreneaux(actualKidSubscriptionsIds)
                }
            })
        } else {
            getCreneaux()
        }

        setLastChooseCenterAndType(choixEcole)
        setLastMesEnfants(mesEnfants)
    }

    const onTimeslotClick = (event) => {
        const formCreneau = forms[formName]
        const formData = forms[formDataName]
        const oldSelectedTimeslot = forms[formName]?.selectedTimeslot || {}

        const selectedTimeslot = formData.timeslots[event.currentTarget.id]
        const timeslotIdentifier = selectedTimeslot.value

        if (isMobile) {
            closeAllTooltipDescription()

            const timeslot = document.querySelector("#description-card-" + timeslotIdentifier)
            if (oldSelectedTimeslot === selectedTimeslot) {
                timeslot.classList.remove("active")
            } else {
                timeslot.classList.add("active")
            }
        }

        if (oldSelectedTimeslot === selectedTimeslot) {
            dispatch(setFormEntry(formName, {
                ...formCreneau,
                selectedTimeslot: {},
                selectedTimeslotValue: undefined,
            }))
            setSelectedTimeslotDetails(undefined)
        } else {
            dispatch(setFormEntry(formName, {
                ...formCreneau,
                selectedTimeslot,
                selectedTimeslotValue: timeslotIdentifier,
            }))

            const schoolDetail = selectedTimeslot.schoolDetails
            setSelectedTimeslotDetails(schoolDetail)

            const skipStepAction = dispatch(checkSkipStepEDF(forms, selectedTimeslot))
            setCheckSkipStepEDFState(skipStepAction.payload)

            if (formRef.current) {
                formRef.current.validate()
            }

            if (schoolDetail) {
                dispatch(setSchoolSubscription(schoolDetail.id, schoolDetail.amount))
                if (schoolDetail.full || !schoolDetail.canRegister) {
                    stepRef.current?.unvalidate()
                } else {
                    stepRef.current?.validate()
                }
            }
        }
    }

    // Subscribe to waiting list
    const subscribeToWaitingList = () => {
        const enfants = forms[MesEnfants.formName]
        const selectedKid = enfants.selectedKid
        const formCreneau = forms[formName]
        const dailySlot = formCreneau.selectedTimeslot

        setAjaxLoading(true)

        const data = {
            child: selectedKid.id,
            childBirthDate: selectedKid.birthDate.split("-").reverse().join("/"),
            childFirstname: selectedKid.firstName,
            childGender: selectedKid.gender,
            childLastname: selectedKid.lastName,
            childOutfitSize: selectedKid.equipment,
            contact1Email: userLoaded.emailAddress,
            contact1Firstname: userLoaded.firstname,
            contact1Lastname: userLoaded.lastname,
            contact1Phone: userLoaded.phoneNumber ? userLoaded.phoneNumber.replace("+33", "0") : "",
            dailySlot: dailySlot.value,
            waitingForAvailability: true,
        }

        editSchoolSubscription(data).then((response) => {
            setAjaxLoading(false)
            const confirmedSub = response.data
            dispatch(clearAllForms())
            dispatch(goToStep(0))

            const lastUniverse = localStorage.getItem("lastUniverse")
            const you = lastUniverse === "theme-padel" ? "te" : "vous"
            const your = lastUniverse === "theme-padel" ? "ton" : "votre"

            navigate("/confirmcommand", {
                state: {
                    confirmedSubscription: confirmedSub,
                    message: `Un email ${you} sera envoyé en cas de places disponibles.`,
                    subtitle: `Nous ${you} confirmons l'inscription de ${your} enfant à la liste d'attente.`,
                    success: true,
                    title: "Inscription à la liste d'attente",
                    tunnelType: "SCHOOL",
                },
            })
        })
    }

    const ozmoRegistration = () => {
        return new Promise((resolve) => {
            const ozmoSubscription = forms.ozmoSubscription
            const enfants = forms[MesEnfants.formName]
            const selectedKid = enfants.selectedKid
            const formCreneau = forms[formName]
            const dailySlot = formCreneau.selectedTimeslot

            const ozmoCall = () => {
                setAjaxLoading(true)

                const data = {
                    child: selectedKid.id,
                    childBirthDate: selectedKid.birthDate.split("-").reverse().join("/"),
                    childFirstname: selectedKid.firstName,
                    childGender: genderValues[selectedKid.gender],
                    childLastname: selectedKid.lastName,
                    childOutfitSize: selectedKid.equipment,
                    contact1Email: userLoaded.emailAddress,
                    contact1Firstname: userLoaded.firstname,
                    contact1Lastname: userLoaded.lastname,
                    contact1Phone: userLoaded.phoneNumber ? userLoaded.phoneNumber.replace("+33", "0") : "",
                    dailySlot: dailySlot.value,
                }

                editSchoolSubscription(data)
                    .then((response) => {
                        const id = response.data.id
                        dispatch(setFormEntry(formName, {
                            ...formCreneau,
                            schoolSubscriptionId: id,
                        }))

                        setSearchParams({
                            ...searchParamsObject,
                            registrationId: id,
                        })

                        dispatch(setFormEntry("ozmoSubscription", {
                            ...forms.ozmoSubscription,
                            firstSubscription: response.data,
                        }))

                        dispatch(removeFormEntry("avoirFormData"))

                        if (response?.data?.id) {
                            const data = response.data
                            const schoolDuration = dayjs(data?.school?.end).diff(dayjs(data?.school?.start), "days")

                            window.dataLayer = window.dataLayer || []
                            window.dataLayer.push({
                                data: {
                                    "cart_id": data?.id,
                                    "product": data?.school?.name,
                                    "product_brand": "Urban Soccer",
                                    "product_category1": "Ecole de foot",
                                    "product_category2": data?.school?.centerName,
                                    "product_category3": data?.school?.name,
                                    "product_category4": data?.school?.start ? dayjs(data?.school?.start).format("ddd DD/MM - HH[h]mm") : "",
                                    "product_id": data?.school?.type,
                                    "product_pricetaxfree": Number(data?.proforma?.amountHT),
                                    "product_pricetaxincluded": Number(data?.proforma?.amountTTC),
                                    "product_quantity": 1,
                                    "product_variant": schoolDuration + " jours",
                                },
                                event: "product.add_to_cart",
                            })
                        }

                        resolve()
                    })
                    .catch((e) => {
                        if (e?.response?.data?.data?.Message) {
                            ModalHandler.show(MessagePopin, {
                                message: [
                                    e?.response?.data?.data?.Message,
                                    ...(e?.response?.data?.data?.Errors?.map(m => m.Message) ?? []),
                                ].join(". "),
                                title: "Erreur",
                            })
                        }
                    })
                    .finally(() => setAjaxLoading(false))
            }

            if (ozmoSubscription) {
                const { oldSelectedKid, selectedKid } = forms[MesEnfants.formName]
                if (ozmoSubscription.firstSubscription.school.id === dailySlot.value) {
                    if (oldSelectedKid && oldSelectedKid.equipment === selectedKid.equipment) {
                        resolve()
                        return
                    }
                }
            }

            dispatch(clearSchoolOptions())
            ozmoCall()
        })
    }

    useEffect(() => {
        getAvailableSlots()
    }, [ forms ])

    useEffect(() => {
        getAvailableSlots(true)
    }, [ currentPrivateCode ])

    const renderTimeslots = () => {
        const formDataCreneau = forms[formDataName]
        const actualKidSubscriptionsIds = formDataCreneau?.actualKidSubscriptionsIds || []
        const form = forms[formName] || { selectedTimeslot: {} }
        const selectedTimeslot = form.selectedTimeslot || {}
        const formData = formDataCreneau || { timeslots: undefined }
        const { timeslots } = formData

        let sortedTimeslots
        if (timeslots) {
            sortedTimeslots = Object.values(timeslots).sort(TimeSort.sortByAscSchoolDay)
        }

        if (!sortedTimeslots) {
            return <Preloader fixed={true} />
        }

        if (sortedTimeslots.length === 0) {
            return (
                <div>
                    Toutes les écoles de football correspondant à votre recherche sont complètes,
                    nous vous invitons à essayer un centre à proximité.
                    <br /><br />
                    N'hésitez pas à contacter votre centre pour plus d'informations.
                </div>
            )
        }

        const child = forms[MesEnfants.formName]?.selectedKid

        return sortedTimeslots.map((timeslot, idx) => {
            const timeslotJsx = (
                <div className="timeslots-div" key={timeslot.value}>
                    <CheckBlock
                        callback={onTimeslotClick}
                        checked={timeslot.value === selectedTimeslot.value}
                        name="timeslots"
                        value={timeslot.value}
                        disabled={
                            (!timeslot.schoolDetails.waitingListOpened && timeslot.schoolDetails.full) ||
                            actualKidSubscriptionsIds.includes(timeslot.schoolDetails.id)
                        }
                        key={timeslot.value}
                        cls="edfCheckB"
                    >
                        <div className="radioList__item__subTitle">
                            {days[timeslot.schoolDetails.day]}
                        </div>
                        <div className="radioList__item__price">
                            <HourDisplay minutes={timeslot.schoolDetails.hour} /> - <HourDisplay minutes={timeslot.schoolDetails.hour + timeslot.schoolDetails.rentalDuration} />
                            {
                                actualKidSubscriptionsIds.includes(timeslot.schoolDetails.id) ? (
                                    <strong>Déjà inscrit{child?.gender === "Fille" ? "e" : ""}</strong>
                                ) : (
                                    timeslot.schoolDetails.full ? (
                                        <strong>COMPLET</strong>
                                    ) : (
                                        <>
                                            <strong><CurrencyDisplay price={timeslot.schoolDetails.amountTotal ?? timeslot.schoolDetails.amount} /></strong>
                                            {
                                                timeslot.schoolDetails.remainingPlaces !== null &&
                                                timeslot.schoolDetails.remainingPlaces !== 0 &&
                                                timeslot.schoolDetails.remainingPlaces <= 3 && (
                                                    <span>
                                                        {timeslot.schoolDetails.remainingPlaces}
                                                        {timeslot.schoolDetails.remainingPlaces > 1 ? " places restantes" : " place restante"}
                                                    </span>
                                                )
                                            }
                                        </>
                                    )
                                )
                            }
                        </div>
                    </CheckBlock>
                </div>
            )

            if (!isMobile) {
                return timeslotJsx
            }

            const result = [ timeslotJsx ]

            if (idx === (sortedTimeslots.length - 1) || (!isEven(idx) && idx !== 0)) {
                if (idx >= 1 && !isEven(idx)) {
                    const previousEl = sortedTimeslots[idx - 1]
                    const previousDetails = previousEl.schoolDetails
                    const previousId = previousEl.value

                    const isDisabled = (!previousDetails.waitingListOpened && previousDetails.full) ||
                        actualKidSubscriptionsIds.includes(previousDetails.id)

                    if (!isDisabled) {
                        result.push(
                            <div
                                className="c-col c-col--3 c-col--sm--12 tooltip-description left"
                                id={`description-card-${previousId}`}
                                key={`description-card-${previousId}`}
                            >
                                {renderDescriptionCard(timeslots, previousDetails)}
                            </div>,
                        )
                    }
                }

                const timeslotDetails = timeslot.schoolDetails
                const timeslotId = timeslot.value

                const isDisabled = (!timeslotDetails.waitingListOpened && timeslotDetails.full) ||
                    actualKidSubscriptionsIds.includes(timeslotDetails.id)

                if (!isDisabled) {
                    let classToApply = "c-col c-col--3 c-col--sm--12 tooltip-description"
                    classToApply += (idx === 0 || isEven(idx)) ? " left" : ""
                    result.push(
                        <div
                            className={classToApply}
                            id={`description-card-${timeslotId}`}
                            key={`description-card-${timeslotId}`}
                        >
                            {renderDescriptionCard(timeslots, timeslotDetails)}
                        </div>,
                    )
                }
            }

            return result
        })
    }

    const privateCodeFunction = () => {
        if (currentPrivateCode) {
            setCurrentPrivateCode(null)
            setSelectedTimeslotDetails(undefined)
            setPrivateCode("")
            setAjaxLoading(false)
            dispatch(setFormEntry(formName, {
                ...forms[formName],
                selectedTimeslot: undefined,
                selectedTimeslotValue: undefined,
            }))
            dispatch(setFormEntry(formDataName, {
                ...forms[formDataName],
                timeslots: Object.fromEntries(
                    Object.entries(forms[formDataName]?.timeslots || {}).filter(([ , slot ]) => !slot.isPrivate),
                ),
            }))
            return
        }

        const codeFormatted = slugify(privateCode)
        if (!privateCode) {
            setAjaxLoading(false)
            setErrorMsg("Veuillez saisir un code")
        } else if (codeFormatted && codeFormatted !== "") {
            setAjaxLoading(true)
            setCurrentPrivateCode(codeFormatted)
        }
    }

    const isEven = (idx) => idx % 2 === 0

    useEffect(() => {
        setErrorMsg(null)
    }, [ privateCode ])

    const resetPrivateSchool = () => {
        setCurrentPrivateCode(null)
        setSelectedTimeslotDetails(undefined)
        setPrivateCode("")
        dispatch(setFormEntry(formName, {
            ...forms[formName],
            selectedTimeslot: undefined,
            selectedTimeslotValue: undefined,
        }))
        dispatch(setFormEntry(formDataName, {
            ...forms[formDataName],
            timeslots: Object.fromEntries(
                Object.entries(forms[formDataName]?.timeslots || {}).filter(([ , slot ]) => !slot.isPrivate),
            ),
        }))
    }

    return (
        <Step
            {...props}
            title="Créneaux disponibles"
            help={<PhoneCallHelp tunnelType="SCHOOL" />}
            className="noMarginBottom slotSelection"
            helpId="crenauxHelp"
            ref={stepRef}
            onValidation={ozmoRegistration}
            promise={true}
            onReturn={resetPrivateSchool}
            goToStep={
                checkSkipStepEDFState?.skipPrestaSupp ?
                    checkSkipStepEDFState.skipEquipment ?
                        stepManagement.currentStep + 3
                        :
                        stepManagement.currentStep + 2
                    :
                    null
            }
        >
            {ajaxLoading && <Preloader fixed={true} />}
            <PreForm>
                <label className="h2 private">
                    <span className="label"><span className="join">Rejoins un cours privé</span></span>
                    <TextInput
                        label="Code"
                        customRootCls={"codeInput"}
                        value={forms[formName]?.privateCode || privateCode || currentPrivateCode || ""}
                        onChange={setPrivateCode}
                        placeholder="Entre ton code"
                        error={errorMsg}
                        actions={[ {
                            onClick: ajaxLoading ?
                                () => {}
                                :
                                privateCodeFunction,
                            render: <ButtonCta
                                text={ajaxLoading ?
                                    <Preloader/>
                                    :
                                    (currentPrivateCode || forms[formName]?.privateCode) ?
                                        "X" : "OK"}
                                noSkew
                                noShadow
                            />,
                        } ]}
                    />
                </label>
            </PreForm>
            <div className="formsHolder c-row formsHolder justify-center align-start c-mrg--btm--4 c-mrg--btm--sm--1 layoutSmall">
                <div className="c-col c-col--5 c-col--sm--12">
                    <FormValidator
                        onValidation={validateStep}
                        onUnvalidation={unvalidateStep}
                        ref={formRef}
                    >
                        <div className="radioList--small--half">
                            {renderTimeslots()}
                        </div>
                    </FormValidator>
                </div>

                {!isMobile && (
                    <div className="c-col c-col--3 c-col--sm--12 sticky">
                        {renderDescriptionCard(forms[formDataName]?.timeslots, selectedTimeslotDetails)}
                    </div>
                )}
            </div>
        </Step>
    )
}

SchoolSlots.propTypes = {
    navigate: oneOfType([ bool, func ]).isRequired,
}

export default SchoolSlots
