import { bool, func, string } from "prop-types"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import ButtonCta from "../../../../../../components/Buttons/button/button-cta"
import InputDate from "../../../../../../components/Forms/InputDate/InputDate"
import { SelectInput } from "../../../../../../components/Inputs/Select/SelectInput.jsx"
import { TextInput } from "../../../../../../components/Inputs/TextInput/TextInput.jsx"
import Preloader from "../../../../../../components/loaders/preloader/preloader.jsx"
import {
    useSchoolTrainingCurrent,
    useSchoolTrainingTunnel,
} from "../../../../../../components/SchoolTrainingSubscriptionContext/SchoolTrainingSubscriptionContext"
import { goToStep } from "../../../../../../store/stepsManagement/actions"
import PreForm from "../../../../../kids/components/preForm/PreForm"
import { SlotChoiceFilters } from "../SlotChoiceFilters/SlotChoiceFilters.jsx"

import "./SlotChoicePreform.scss"

export default function SlotChoicePreform({ onPrivateCodeChange, currentPrivateCode, codeError }) {
    const currentSelection = useSchoolTrainingCurrent()
    const { value: tunnelType, setValue: setTunnelType } = useSchoolTrainingTunnel()
    const { value: { selectedAreaId, areas, periodStart, isGlobalLoading }, setValue: setCurrentSelection } = currentSelection
    const [ modalOpen, setModalOpen ] = useState(false)
    const [ privateCode, setPrivateCode ] = useState("")

    useEffect(() => {
        if (!currentPrivateCode) {
            setPrivateCode("")
        }
    }, [ currentPrivateCode ])

    const dispatch = useDispatch()

    const setPeriodStart = (key, value) => setCurrentSelection(prev => ({ ...prev, [key]: value }))

    return (
        <PreForm customCls={`slotChoicePreform ${tunnelType === "SCHOOL" ? "withPrivateCode" : ""}`}>
            <div className="flexVerticalOnMobile">
                <label className="h2">
                    <span>Ville :</span>
                    <SelectInput
                        disabled={!areas}
                        value={selectedAreaId}
                        options={ areas?.length > 0 ?
                            areas.map(x => {
                                return { label: x.name, value: x.id }
                            })
                            :
                            undefined
                        }
                        label={"Sélectionne une ville"}
                        onChange={e => {
                            setTunnelType(undefined)
                            setPrivateCode("")
                            setCurrentSelection(prev => ({
                                ...prev,
                                availableSlots: [],
                                prestation: undefined,
                                privateCode: "",
                                selectedAreaCenters: areas?.find(area => area.id === parseInt(e?.target?.value))?.centers,
                                selectedAreaId: parseInt(e?.target?.value),
                            }))
                            onPrivateCodeChange("")
                            dispatch(goToStep(1))

                        }}
                        placeholderOption="Sélectionne une ville"
                        required
                    />
                </label>
                <label className="h2">
                    <span>Date :</span>
                    <InputDate
                        bind={[ periodStart, "periodStart" ,setPeriodStart ]} 
                        min={new Date()}
                        useDatePicker
                        customClass={
                            "customDayPicker"
                        }
                        position="left"
                        checkValue
                        label={"Sélectionne une date"}
                    />
                </label>
            </div>
            {tunnelType === "SCHOOL" ? (
                <div className="rightPart">
                    <label className="h2 private">
                        <span className="label">Rejoins un cours privé :</span>
                        <TextInput
                            label="Code"
                            customRootCls={"codeInput"}
                            value={privateCode || currentPrivateCode || ""}
                            onChange={setPrivateCode}
                            placeholder="Entre ton code"
                            error={codeError ? "Code incorrect" : ""}
                            actions={[ {
                                onClick: isGlobalLoading ?
                                    () => {}
                                    :
                                    () => {
                                        onPrivateCodeChange(privateCode)

                                        if (currentPrivateCode) {
                                            setPrivateCode("")
                                        }
                                    },
                                render: <ButtonCta
                                    text={isGlobalLoading ?
                                        <Preloader/>
                                        :
                                        currentPrivateCode ?
                                            "X" : "OK"}
                                    noSkew
                                    noShadow
                                />,
                            } ]}
                        />
                    </label>
                    <div className="moreOptions">
                        <ButtonCta
                            isAlt
                            text= "+ plus d'options"
                            onClick={() => setModalOpen(true)}
                        />
                        <SlotChoiceFilters isOpen={modalOpen} setIsOpen={() => setModalOpen(false)} />
                    </div>
                </div>
            ) : (
                <div className="moreOptions">
                    <ButtonCta
                        isAlt
                        text= "+ plus d'options"
                        onClick={() => setModalOpen(true)}
                    />
                    <SlotChoiceFilters isOpen={modalOpen} setIsOpen={() => setModalOpen(false)} />
                </div>
            )}
        </PreForm>
    )
}

SlotChoicePreform.propTypes = {
    codeError: bool,
    currentPrivateCode: string,
    onPrivateCodeChange: func,
}
